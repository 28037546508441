<template>
    <div class="user-guide">
      <UserInfo />
      <BackHomeButton />
      <div class="container">
        <h1 class="title">使用手册</h1>
        <div class="section">
          <h2>1. 图像反推</h2>
          <p>欢迎使用图像描述生成工具！本工具通过JoyCaption推理图像对应的文字描述。</p>
          <ul>
            <li>点击“上传图片”区域，从您的设备中选择您希望生成描述的图片。</li>
            <li>支持的图片格式包括：JPEG, PNG, WEBP。(图片大小限制：建议图片大小不超过10MB)</li>
            <li>上传后，您可以在预览区域查看已上传的图片</li>
            <p></p>
            <li>确认图片上传无误后，根据需要选择需要的选项，点击页面上的“生成描述”按钮。</li>
            <li>系统将开始处理图片并进行文字描述的生成。此过程可能需要几秒钟时间，请耐心等待。</li>
            <li>生成完成后，您可以在右侧结果区域查看生成对应的中英文描述。</li>
            <li>如果遇到服务器繁忙，请耐心等待，10分钟后重新尝试。</li>
          </ul>
        </div>
        <div class="section">
          <h2>2. Lora模型训练</h2>
          <p>开发中！！！</p>
          <!-- <ul>
            <li>任务列表显示所有已创建的模型训练任务。</li>
            <li>点击“查看详情”按钮查看任务的详细信息。</li>
          </ul> -->
        </div>
        <div class="section">
          <h2>3. 批量标注</h2>
          <p>本工具专为Lora模型训练设计，通过上传包含多张图片的ZIP压缩包，快速对多张图片进行标注，生成对应图片的txt描述文件。</p>
          <ul>
            <li>先将需要标注的图片放在一个文件夹内，压缩为ZIP格式压缩包。</li>
            <li>点击新建任务，上传ZIP压缩包。（上传过程不要关闭页面，上传完成会自动跳转到任务列表页面）</li>
            <li>提示：如果压缩包较大，上传时间可能较长，请关注上传进度条耐心等待。</li>
            <li>上传完成后任务将自动开始，需要手动刷新页面查看任务进度，任务完成后会显示"下载结果"按钮。</li>
            <li>点击"下载结果"按钮，下载包含所有图片和描述的ZIP压缩包。</li>
            <li>如遇到下载失败，可将鼠标放在下载结果按钮上，右键选择复制链接，新打开一个浏览器标签，粘贴链接地址，即可下载。</li>
          </ul>
        </div>
        <div class="section">
          <h2>4. ComfyUI服务</h2>
          <p>提供定制ComfyUI服务，流畅运行Flux模型。</p>
          <ul>
            <li>在主界面中，您会看到“获取ComfyUI服务”按钮。</li>
            <li>点击该按钮（积分需要大于600分），系统将分配ComfyUI服务链接。</li>
            <li>成功获取服务后，点击链接即可访问ComfyUI服务。</li>
            <li>点击页面的"刷新页面"按钮，可获取ComfyUI生成的图片。</li>
            <li>点击页面的"全部打包"按钮，可将图片自动压缩为ZIP压缩包。</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import UserInfo from '@/components/UserInfo.vue';
  import BackHomeButton from '@/components/BackHomeButton.vue';
  
  export default {
    name: 'UserGuide',
    components: {
      UserInfo,
      BackHomeButton
    }
  };
  </script>
  
  <style scoped>
  .user-guide {
    min-height: 100vh;
    background: linear-gradient(to bottom, #1a202c, #2d3748);
    color: #e2e8f0;
    font-family: 'Inter', sans-serif;
    padding: 3rem 1rem;
  }
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    background: linear-gradient(to right, #60a5fa, #a78bfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .section {
    background-color: #2d3748;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  .section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .section p {
    margin-bottom: 0.5rem;
  }
  .section ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  .section li {
    margin-bottom: 0.5rem;
  }
  </style>