<template>
    <div class="batch-labeling">
      <UserInfo />
      <div class="container">
        <BackHomeButton />
        <h1 class="title">新建Lora训练任务</h1>
        <!-- <div class="batch-notice">
          <p class="warning">* 标注任务每张图片将消耗10积分，请确保积分充足！</p>
          <p>请保证压缩包(压缩包格式必须是zip格式)内是一个文件夹，文件名称使用英文</p>
        </div> -->
        <!-- <div class="content-box">
          <div
            class="upload-area"
            @click="triggerFileInput"
            @dragover.prevent
            @drop.prevent="handleDrop"
            :class="{ 'disabled': isUploading || isProcessing }"
          >
            <transition name="fade" mode="out-in">
              <div v-if="files.length === 0" key="empty" class="upload-content">
                <svg class="upload-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                <p class="upload-text">点击上传或拖放ZIP压缩包</p>
                <p class="upload-subtext">仅支持ZIP格式</p>
              </div>
              <div v-else key="files" class="files-selected">
                <svg class="check-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <p>{{ files.length }} 个文件已选择</p>
              </div>
            </transition>
          </div>
          <div class="input-group">
            <input v-model="triggerWord" placeholder="输入模型触发词（可选）" class="full-width-input" />
          </div>
          <div v-if="uploadProgress > 0" class="progress-bar">
            <div class="progress" :style="{ width: uploadProgress + '%' }"></div>
          </div>
        </div> -->
        <button
          @click="startProcessing"
          :disabled="files.length === 0 || isProcessing"
          :class="{ 'processing': isProcessing }"
        >
          {{ isProcessing ? '文件上传中...' : '点击新建' }}
        </button>
      </div>
      <input type="file" ref="fileInput" @change="handleFileChange" accept=".zip" class="hidden-input">
    </div>
  </template>
  <script>
  import BackHomeButton from '@/components/BackHomeButton.vue';
  import UserInfo from '@/components/UserInfo.vue';
  import axios from 'axios';
  import SparkMD5 from 'spark-md5';
  export default {
    name: 'NewBatchTask',
    components: {
      BackHomeButton,
      UserInfo
    },
    data() {
      return {
        files: [],
        isProcessing: false,
        isUploading: false,
        fileInput: null,
        taskId: null,
        taskProgress: 0,
        triggerWord: '',
        chunkSize: 5 * 1024 * 1024, // 5MB 每块
        uploadProgress: 0 // 上传进度
      };
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleFileChange(event) {
        const newFiles = Array.from(event.target.files);
        this.addValidFiles(newFiles);
        event.target.value = ''; // 清空 input
      },
      handleDrop(event) {
        const newFiles = Array.from(event.dataTransfer.files);
        this.addValidFiles(newFiles);
      },
      addValidFiles(newFiles) {
        const validFiles = newFiles.filter(file => {
          const isValid = file.name.toLowerCase().endsWith('.zip');
          if (!isValid) {
            alert('只支持ZIP格式文件');
          }
          return isValid;
        });
        this.files = validFiles;
      },
      async calculateMD5(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const spark = new SparkMD5.ArrayBuffer();
            spark.append(e.target.result);
            resolve(spark.end());
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
      },
      async uploadChunk(chunk, filename, chunkNumber, totalChunks, fileHash) {
        const formData = new FormData();
        formData.append('file', chunk, filename);
        formData.append('chunkNumber', chunkNumber);
        formData.append('totalChunks', totalChunks);
        formData.append('fileHash', fileHash);
        const token = localStorage.getItem('access_token');
        
        await axios.post('/api/upload-chunk', formData, {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((chunkNumber / totalChunks) * 100); // 计算进度
          }
        });
      },
      async startProcessing() {
        if (this.files.length === 0) {
          alert('请先选择文件');
          return;
        }
        this.isProcessing = true;
        this.uploadProgress = 0; // 重置上传进度
        
        try {
          const token = localStorage.getItem('access_token');
          const file = this.files[0];
          const fileHash = await this.calculateMD5(file);
          const totalChunks = Math.ceil(file.size / this.chunkSize);
          
          // 先上传所有分片
          for (let i = 0; i < totalChunks; i++) {
            const start = i * this.chunkSize;
            const end = Math.min(file.size, start + this.chunkSize);
            const chunk = file.slice(start, end);
            
            await this.uploadChunk(chunk, file.name, i + 1, totalChunks, fileHash);
          }
          
          // 所有分片上传完成后，调用合并接口
          const response = await axios.post('/api/merge-chunks', {
            filename: file.name,
            triggerWord: this.triggerWord,
            fileHash: fileHash,
            totalChunks: totalChunks
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (response.data.task === 'ok') {
            this.$router.push('/batch-labeling');
          } else {
            throw new Error('Failed to create task');
          }
        } catch (error) {
          console.error('Error uploading file:', error);
          alert('上传文件时发生错误，请重试。');
        } finally {
          this.isProcessing = false;
          this.uploadProgress = 0; // 上传完成后重置进度
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .batch-labeling {
    min-height: 100vh;
    background: linear-gradient(to bottom, #1a202c, #2d3748);
    color: #e2e8f0;
    font-family: 'Inter', sans-serif;
    padding: 3rem 1rem;
  }
  .container {
    max-width: 48rem;
    margin: 0 auto;
  }
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    background: linear-gradient(to right, #60a5fa, #a78bfa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content-box {
    background-color: #2d3748;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
  .upload-area {
    border: 2px dashed #4a5568;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .upload-area:hover {
    border-color: #60a5fa;
    background-color: rgba(96, 165, 250, 0.1);
  }
  .upload-icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    color: #a0aec0;
  }
  .upload-text {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  .upload-subtext {
    font-size: 0.875rem;
    color: #a0aec0;
  }
  .files-selected {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .check-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    color: #48bb78;
  }
  button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: white;
    background: linear-gradient(to right, #60a5fa, #a78bfa);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  button:hover:not(:disabled) {
    opacity: 0.9;
    transform: translateY(-2px);
  }
  button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  button.processing {
    background-size: 200% 200%;
    animation: gradient 1.5s ease infinite;
  }
  @keyframes gradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  .hidden-input {
    display: none;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .task-info, .task-status {
    margin-top: 1rem;
    background-color: #2d3748;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .file-list {
    max-height: 400px;
    overflow-y: auto;
    background-color: #2a2a2a;
    border: 1px solid #4a5568;
    border-radius: 4px;
    padding: 0.5rem;
    margin-top: 0.5rem;
  }
  .file-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: #333;
    border-radius: 4px;
  }
  .thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 1rem;
    border-radius: 4px;
  }
  .file-item span {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .batch-notice .warning {
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #2d3748;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1rem;
  }
  .progress {
    height: 100%;
    background-color: #4299e1;
    transition: width 0.3s ease;
  }
  .input-group {
    margin-top: 1rem;
  }
  .full-width-input {
    width: 100%;
    padding: 0.75rem;
    background-color: #3a3a3a;
    color: #e0e0e0;
    border: 1px solid #4a5568;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  .full-width-input:focus {
    border-color: #3182ce;
    outline: none;
  }
</style>