<template>
  <div class="comfy-ui">
    <UserInfo />
    <div class="header">
      <BackHomeButton />
      <h1>我的ComfyUI 作品</h1>
    </div>
    <p class="notice">图片保留3小时，请及时下载</p>
    <div class="content">
      <div class="content-header">
        <button @click="refreshPage" class="refresh-btn">刷新页面</button>
        <button 
          @click="downloadAllFiles" 
          class="download-all-btn"
          :disabled="isPackaging"
        >
          <span v-if="isPackaging" class="loading-spinner"></span>
          {{ isPackaging ? '打包中...' : '全部打包' }}
        </button>
        <button @click="openDialog" class="get-resources-btn">获取ComfyUI</button>
        <button @click="releaseResources" class="release-resources-btn">释放资源</button>
      </div>
      <div v-if="isLoading" class="loading">加载中...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else-if="images.length === 0 && zipFiles.length === 0" class="no-images">暂无图片</div>
      <div v-else class="image-grid">
        <div v-for="zipFile in zipFiles" :key="zipFile.id" class="image-card">
          <div class="image-container">
            <div class="zip-icon-container">
              <img src="/zip_icon.png" alt="ZIP file" class="zip-icon" />
            </div>
            <div class="overlay">
              <button @click="downloadZipFile(zipFile)" class="download-btn">下载压缩包</button>
            </div>
          </div>
          <p class="image-title">{{ zipFile.title }}</p>
        </div>
        <div v-for="image in images" :key="image.id" class="image-card">
          <div class="image-container">
            <img :src="getWebpUrl(image.url)" :alt="image.title" />
            <div class="overlay">
              <button @click="downloadImage(image)" class="download-btn">下载原图</button>
            </div>
          </div>
          <p class="image-title">{{ image.title }}</p>
        </div>
      </div>
    </div>
    <!-- Dialog Box -->
    <div v-if="showDialog" class="dialog-overlay" @click.self="closeDialog">
      <div class="dialog-box">
        <button @click="closeDialog" class="close-btn"></button>
        <h2>获取ComfyUI访问链接</h2>
        <div class="points-notice">
          <p>ComfyUI服务约{{ hourlyPrice }}元每小时</p>
          <p class="warning">* 用完务必点击释放资源按钮进行释放，否则将持续扣费</p>
          <p class="warning">* 使用时间30分钟起，不足30分钟按30分钟计费</p>
        </div>
        <p>使用ComfyUI服务剩余积分需要大于600积分
          <router-link to="/buy-points" class="buy-points-link">
            点我购买
            <span class="arrow">→</span>
          </router-link>
        </p>
        <p>积分不足ComfyUI服务将自动关闭!</p>
        <div class="resource-links-table">
          <table v-if="resourceLinks.length > 0">
            <tbody>
              <tr v-for="(link, index) in resourceLinks" :key="index">
                <td>
                  <a :href="link" target="_blank" rel="noopener noreferrer">
                    ComfyUI链接(点击开始使用) {{ index + 1 }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-else class="no-resources-message">
            {{ count === 0 ? '当前资源不足，请五分钟后再试' : '没有资源' }}
          </p>
        </div>
        <button @click="getResource" class="ok-btn" :disabled="isGettingResource">
          {{ isGettingResource ? '已获取' : '确定获取' }}
        </button>
      </div>
    </div>
    
    <div v-if="showReleaseDialog" class="dialog-overlay" @click.self="closeReleaseDialog">
      <div class="dialog-box">
        <button @click="closeReleaseDialog" class="close-btn"></button>
        <h2>当前资源列表</h2>
        <div class="resource-table">
          <table>
            <thead>
              <tr>
                <th><input type="checkbox" v-model="selectAll" @change="toggleSelectAll"></th>
                <th>资源地址</th>
                <th>开始时间</th>
                <th>已使用时长</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(resource, index) in userResources" :key="index">
                <td>
                  <input 
                    type="checkbox" 
                    :value="resource"
                    v-model="selectedResources"
                    :id="'resource-' + index"
                  >
                </td>
                <td>{{ resource.comfyui_url }}</td>
                <td>{{ formatTime(resource.start_time) }}</td>
                <td>{{ calculateUsedTime(resource.start_time) }}分钟</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button 
          @click="releaseSelectedResources" 
          class="release-btn"
          :disabled="!selectedResources.length"
        >
          释放选中资源
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import BackHomeButton from '@/components/BackHomeButton.vue';
import UserInfo from '@/components/UserInfo.vue';
export default {
  name: 'ComfyUI',
  components: {
    BackHomeButton,
    UserInfo
  },
  data() {
    return {
      images: [],
      isLoading: true,
      error: null,
      showDialog: false,
      resourceLinks: [],
      newLink: '',
      isGettingResource: false,
      hours: 1,
      points: 300,
      showReleaseDialog: false,
      userResources: [],
      selectedResources: [],
      selectAll: false,
      zipFiles: [],
      isPackaging: false,
      hourlyPrice: 1.9,
      count: null,
    };
  },
  methods: {
    checkAuth() {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          this.$router.push('/login');
        } else {
          this.isLoggedIn = true;
        }
      } catch (error) {
        console.error('检查登录状态时发生错误:', error);
        this.isLoggedIn = false;
      }
    },
    async fetchImages() {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          throw new Error('No access token found');
        }
        const response = await axios.get('/api/comfyui-images', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.images = response.data.filter(file => file.type === 'image');
        this.zipFiles = response.data.filter(file => file.type === 'zip');
      } catch (err) {
        console.error('Failed to fetch images:', err);
        this.error = '获取图片失败，请稍后再试';
      } finally {
        this.isLoading = false;
      }
    },
    async downloadImage(image) {
      try {
        const response = await fetch(image.url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = image.title + '.png';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (err) {
        console.error('Download failed:', err);
        alert('下载失败，请稍后再试');
      }
    },
    async openDialog() {
      this.showDialog = true;
      this.hours = 1;
      this.points = 300;
      
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/api/get-comfyui', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data.hourly_price !== undefined) {
          this.hourlyPrice = response.data.hourly_price;
        }
        
        if (response.data.server && response.data.server.length > 0) {
          this.resourceLinks = response.data.server.map(server => server.comfyui_url);
          this.isGettingResource = true;
        } else {
          this.resourceLinks = [];
          this.isGettingResource = false;
        }
        this.count = response.data.count;
      } catch (err) {
        console.error('Failed to get current resources:', err);
        this.resourceLinks = [];
        this.isGettingResource = false;
      }
    },
    closeDialog() {
      this.showDialog = false;
      this.resourceLinks = [];
      this.isGettingResource = false;
    },
    async getResource() {
      if (this.isGettingResource) return;
      this.isGettingResource = true;
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post('/api/comfyui-server', 
          { hours: this.hours },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        if (response.data.hourly_price !== undefined) {
          this.hourlyPrice = response.data.hourly_price;
        }
        
        if (response.data.points === 0) {
          this.$router.push('/buy-points');
        } else if (response.data.link) {
          const newLink = response.data.link;
          this.resourceLinks = newLink;
        } else {
          this.error = '获取资源失败，请稍后再试';
        }
        this.count = response.data.count;
      } catch (err) {
        console.error('Failed to get resource:', err);
        this.error = '获取资源失败，请稍后再试';
      }
    },
    async releaseResources() {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/api/get-comfyui', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.userResources = response.data.server || [];
        this.selectedResources = [];
        this.showReleaseDialog = true;
      } catch (err) {
        console.error('Failed to get resources:', err);
        alert('获取资源失败，请稍后再试');
      }
    },
    closeReleaseDialog() {
      this.showReleaseDialog = false;
      this.selectedResources = [];
      this.selectAll = false;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.selectedResources = [...this.userResources];
      } else {
        this.selectedResources = [];
      }
    },
    calculateUsedTime(startTime) {
      const now = Math.floor(Date.now() / 1000);
      const usedSeconds = now - startTime;
      return Math.floor(usedSeconds / 60);
    },
    formatTime(timestamp) {
      return new Date(timestamp * 1000).toLocaleString();
    },
    async releaseSelectedResources() {
      if (!this.selectedResources.length) return;
      
      try {
        const token = localStorage.getItem('access_token');
        await axios.post('/api/release-comfyui', {
          comfyui_id: this.selectedResources[0].id
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        
        this.closeReleaseDialog();
      } catch (err) {
        console.error('Failed to release resources:', err);
        alert('释放资源失败，请联系管理员');
      }
    },
    async downloadAllFiles() {
      if (this.isPackaging) return;
      this.isPackaging = true;
      
      try {
        const token = localStorage.getItem('access_token');
        await axios.get('/api/download-all-comfyui', {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          timeout: 60000  // 设置超时
        });
        
        await this.refreshPage();
        
      } catch (err) {
        console.error('Package failed:', err);
        // alert('打包失败，请稍后再试');
      } finally {
        this.isPackaging = false;
      }
    },
    async downloadZipFile(zipFile) {
      try {
        const response = await fetch(zipFile.url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = zipFile.title + '.zip';
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (err) {
        console.error('Download failed:', err);
        alert('下载失败，请稍后再试');
      }
    },
    async refreshPage() {
      this.isLoading = true;
      try {
        await this.fetchImages();
      } catch (error) {
        console.error('Failed to refresh:', error);
      } finally {
        this.isLoading = false;
      }
    },
    getWebpUrl(originalUrl) {
      return originalUrl.replace(/\.(png|jpg|jpeg)$/i, '.webp');
    },
  },
  mounted() {
    this.checkAuth();
    this.fetchImages();
  }
};
</script>
<style scoped>
.comfy-ui {
  padding: 30px 0;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #121212;
  color: #e0e0e0;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;
  margin-top: 60px;
}
.header h1 {
  margin-left: 20px;
  flex-grow: 1;
  text-align: center;
  color: #e0e0e0;
}
.notice {
  text-align: center;
  color: #ff6b6b;
  margin-bottom: 20px;
  font-weight: bold;
}
.content {
  background-color: #1a1a1a;
  padding: 20px 10px;
}
.content-header {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 20px;
}
.loading, .error, .no-images {
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
  color: #e0e0e0;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.image-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}
.image-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 10 / 15;
}
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}
.download-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffd700;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
}
.image-card:hover .download-btn {
  opacity: 1;
}
.download-btn:hover {
  background-color: #ffed4a;
  transform: translateX(-50%) translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
.refresh-btn,
.download-all-btn,
.get-resources-btn,
.release-resources-btn {
  background-color: #4CAF50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.refresh-btn:hover,
.download-all-btn:hover,
.get-resources-btn:hover,
.release-resources-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}
.dialog-box {
  background-color: #2a2a2a;
  padding: 30px;
  border-radius: 12px;
  width: 50vw;
  max-width: 800px;
  min-width: 600px;
  text-align: left;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  animation: dialogAppear 0.3s ease-out;
}
@keyframes dialogAppear {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.dialog-box h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #e0e0e0;
  font-size: 1.5rem;
  font-weight: 600;
}
.dialog-box p {
  margin-bottom: 20px;
  color: #b0b0b0;
  font-size: 1rem;
}
.resource-links-table {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.resource-links-table table {
  width: 100%;
  border-collapse: collapse;
}
.resource-links-table th,
.resource-links-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #444;
}
.resource-links-table th {
  background-color: #1a1a1a;
  color: #e0e0e0;
}
.resource-links-table a {
  color: #4CAF50;
  text-decoration: none;
  transition: color 0.3s ease;
}
.resource-links-table a:hover {
  color: #45a049;
  text-decoration: underline;
}
.ok-btn {
  background-color: #4CAF50;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  width: 100%;
}
.ok-btn:hover:not(:disabled) {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.ok-btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.close-btn:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.close-btn::before  {
  content: '×';
}
.resource-table {
  margin: 1rem 0;
  max-height: 400px;
  overflow-y: auto;
}
.resource-table table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1a1a1a;
  table-layout: fixed;
}
.resource-table th,
.resource-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.resource-table th {
  background-color: #2a2a2a;
  font-weight: bold;
}
.resource-table tbody tr:hover {
  background-color: #2a2a2a;
}
.release-btn {
  background-color: #dc3545;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: 1rem;
}
.release-btn:hover:not(:disabled) {
  background-color: #c82333;
}
.release-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
input[type="checkbox"] {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.resource-table th:first-child,
.resource-table td:first-child {
  width: 50px;
}
.resource-table th:nth-child(2),
.resource-table td:nth-child(2) {
  width: 40%;
}
.resource-table th:nth-child(3),
.resource-table td:nth-child(3) {
  width: 30%;
}
.resource-table th:last-child,
.resource-table td:last-child {
  width: 20%;
  text-align: center;
}
.resource-table th:last-child,
.resource-table td:last-child {
  text-align: center;
  min-width: 100px;
}
.points-notice {
  background-color: #2d3748;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  border: 1px solid #4CAF50;
}
.points-notice p {
  margin: 0.5rem 0;
  color: #e0e0e0;
  font-weight: bold;
  text-align: center;
}
.points-notice .warning {
  color: #ff6b6b;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
.buy-points-link {
  display: inline-block;
  color: #4CAF50;
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none;
  padding: 4px 8px;
  margin-left: 8px;
  border-radius: 4px;
  background-color: rgba(76, 175, 80, 0.1);
  transition: all 0.3s ease;
}
.buy-points-link:hover {
  background-color: rgba(76, 175, 80, 0.2);
  transform: translateX(2px);
}
.buy-points-link .arrow {
  display: inline-block;
  margin-left: 4px;
  transition: transform 0.3s ease;
}
.buy-points-link:hover .arrow {
  transform: translateX(3px);
}
.zip-icon-container {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d3748;
  padding: 1rem;
}
.zip-icon {
  width: 200px;
  height: 400px;
  object-fit: contain;
}
.image-title {
  text-align: center;
  padding: 10px;
  color: #e0e0e0;
  word-break: break-all;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.refresh-btn:active {
  animation: spin 0.5s linear;
}
.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 2px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.download-all-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-all-btn:disabled {
  opacity: 0.7;
  cursor: wait;
}
</style>