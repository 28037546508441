import Vue from 'vue'
import VueRouter from 'vue-router'
import AiToolHome from '../components/AiToolHome.vue'
import ImageCaption from '../views/ImageCaption.vue'
import ModelTraining from '../views/ModelTraining.vue'
import BatchLabeling from '../views/BatchLabeling.vue'
import ImageUpscale from '../views/ImageUpscale.vue'
import ComfyUI from '../views/ComfyUI.vue'
import ContactPage from '../views/Contact.vue'
import Help from '../views/Help.vue'
import Login from '../views/Login.vue'
import BuyPoints from '@/views/BuyPoints.vue'
import Recharge from '@/views/Recharge.vue'
import NewBatchTask from '@/views/NewBatchTask.vue'
import NewTrainTask from '@/views/NewTrainTask.vue'
import ChatGPT from '@/views/ChatGPT.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: AiToolHome },
  { path: '/image-caption', component: ImageCaption },
  { path: '/model-training', component: ModelTraining},
  { path: '/batch-labeling', component: BatchLabeling },
  { path: '/image-upscale', component: ImageUpscale},
  { path: '/comfyui', component: ComfyUI},
  { path: '/help', component: Help },
  { path: '/contact', component: ContactPage },
  { path: '/login', component: Login },
  { path: '/buy-points', name: 'BuyPoints', component: BuyPoints},
  { path: '/add-point-admin', name: 'Recharge', component: Recharge},
  { path: '/batch-labeling/new', name: 'NewBatchTask', component: NewBatchTask},
  { path: '/model-training/new', name: 'NewTrainTask', component: NewTrainTask},
  {path: '/vip/chatgpt', name: 'ChatGPT', component: ChatGPT}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/image-caption', '/contact']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('access_token')
  if (authRequired && !loggedIn) {
    return next('/login')
  }
  next()
})

export default router