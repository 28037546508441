<template>
  <div class="buy-points">
    <BackHomeButton />
    <h1 class="title">购买积分</h1>
    <p class="subtitle">解锁更多创意可能，提升您的 AI 体验！</p>
    
    <div class="points-packages">
      <div 
        v-for="pointPackage in packages" 
        :key="pointPackage.id" 
        class="package-card"
        :class="{ 'featured': pointPackage.featured }"
      >
        <div class="package-content">
          <!-- <h2>{{ pointPackage.name }}</h2> -->
          <p class="points">{{ pointPackage.points }} 积分</p>
          <p class="price">¥{{ pointPackage.price }}</p>
          <ul class="features">
            <li v-for="feature in pointPackage.features" :key="feature">
              <span class="feature-icon">✓</span> {{ feature }}
            </li>
          </ul>
        </div>
        <button @click="buyPackage(pointPackage)" class="buy-button">
          立即购买
        </button>
      </div>
    </div>
    <PaymentModal 
      :visible="showPaymentModal"
      :amount="selectedPackage ? selectedPackage.price : 0"
      @close="closePaymentModal"
    />
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BackHomeButton from '@/components/BackHomeButton.vue';
import PaymentModal from '@/components/PaymentModal.vue';  // 确保路径正确
export default defineComponent({
  name: 'BuyPoints',
  components: {
    BackHomeButton,
    PaymentModal
  },
  setup() {
    const packages = ref([
      {
        id: 1,
        name: '体验包',
        points: 2500,
        price: 20,
        features: []
      },
      {
        id: 2,
        name: '入门包',
        points: 6500,
        price: 50,
        features: []
      },
      {
        id: 3,
        name: '进阶包',
        points: 14000,
        price: 100,
        features: [],
        featured: true
      },
      {
        id: 4,
        name: '专业包',
        points: 30000,
        price: 200,
        features: []
      },
      {
        id: 5,
        name: '钻石包',
        points: 45000,
        price: 300,
        features: []
      },
      {
        id: 6,
        name: '黄钻包',
        points: 80000,
        price: 500,
        features: []
      }
    ]);
    const showPaymentModal = ref(false);
    const selectedPackage = ref(null);
    const buyPackage = (pointPackage) => {
      selectedPackage.value = pointPackage;
      showPaymentModal.value = true;
    };
    const closePaymentModal = () => {
      showPaymentModal.value = false;
      selectedPackage.value = null; 
    };
    return {
      packages,
      buyPackage,
      showPaymentModal,
      selectedPackage,
      closePaymentModal
    };
  }
});
</script>
<style scoped>
.buy-points {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  color: #e0e0e0;
  background-color: #121212;
}
.title {
  text-align: center;
  color: #4CAF50;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
.subtitle {
  text-align: center;
  color: #b0b0b0;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.points-packages {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  row-gap: 4rem;     /* 控制行间距 */
  column-gap: 2rem;  /* 控制列间距 */
  margin-bottom: 3rem;
}
.package-card {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}
.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.package-card.featured {
  border: 2px solid #4CAF50;
  position: relative;
}
.package-card.featured::before {
  content: '推荐';
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
}
.package-content {
  flex-grow: 1;
}
.package-card h2 {
  color: #4CAF50;
  margin-bottom: 0.75rem;
  font-size: 1.3rem;
}
.points {
  font-size: 1.6rem;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 0.5rem;
}
.price {
  font-size: 1.3rem;
  color: #e0e0e0;
  margin-bottom: 0.75rem;
}
.features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.25rem;
  text-align: left;
}
.features li {
  margin-bottom: 0.4rem;
  color: #b0b0b0;
  font-size: 0.95rem;
}
.feature-icon {
  color: #4CAF50;
  margin-right: 0.3rem;
}
.buy-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}
.buy-button:hover {
  background-color: #45a049;
}
@media (max-width: 1200px) {
  .points-packages {
    grid-template-columns: repeat(2, minmax(260px, 1fr));
  }
}
@media (max-width: 768px) {
  .points-packages {
    grid-template-columns: minmax(260px, 1fr);
  }
}
</style>